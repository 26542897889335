// import TCPServerNOC from './app/TCPServerNOC';
import UDPServerNOC from './app/UDPServerNOC';

import './theme/style.css';

function App() {
  return <UDPServerNOC />;
}

export default App;
