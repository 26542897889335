import React from 'react';
import { Buffer } from 'buffer';

import UDPServerNOCPresentation from './UDPServerNOCPresentation';
import {
    listAllConnectedDevices,
    getDeviceMessages,
} from '../../services/TCPServerAPIClient';

class UDPServerNOCContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allMessages: [],
        }
    }

    pollAllDevicesMessages = async () => {

        const { data: connectedDevices } = await listAllConnectedDevices();
        const allMessages = (await Promise.allSettled(connectedDevices.map(async connectedDevice => {
            const { data: messages } = await getDeviceMessages(connectedDevice.id);

            return messages.map(message => ({
                source: connectedDevice.id,
                ...message
            }));
        })))
            .flatMap(response => response.value)
            .map(message => ({
                ...message,
                timestamp: new Date(message.timestamp),
                data: Buffer.from(message.data, 'base64')
                    .toString('hex')
                    .toUpperCase(),
            }));

        this.setState({
            allMessages,
        });
    }

    componentDidMount() {
        this.pollAllDevicesMessages();
        this.pollAllDevicesMessagesInterval = setInterval(this.pollAllDevicesMessages, 2500);
    }

    componentWillUnmount() {
        if (this.pollAllDevicesMessagesInterval) {
            clearInterval(this.pollAllDevicesMessagesInterval);
        }
    }

    render() {
        const allMessages = this.state.allMessages;

        return <UDPServerNOCPresentation
            allMessages={allMessages}
        />;
    }
}

export default UDPServerNOCContainer;