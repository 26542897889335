import React, { useState } from "react";

import { styled } from '@mui/material/styles';

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableRow } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const UDPServerNOCPresentation = (props) => {

    const {
        allMessages = [],
    } = props;

    const [currentPage, setCurrentPage] = useState(0);

    return <Grid
        container
        spacing={2}>

        <Grid item xs={12}>
            <Item>uDEV - NBS300 - Server NOC</Item>
        </Grid>

        <Grid item xs={12}>
            <Item>Last Server Events</Item>
            <TableContainer
                component={Paper}
            >
                <Table>
                    <TableBody>
                        {allMessages
                            .sort((mlho, mrho) => mrho.timestamp - mlho.timestamp)
                            .slice(currentPage * 10, (currentPage + 1) * 10)
                            .map((message, idx) => (
                                <TableRow
                                    key={`tcp-server-event-${idx}`}
                                >
                                    <TableCell>{message.source}</TableCell>
                                    <TableCell>{message.timestamp.toString()}</TableCell>
                                    <TableCell>{message.data}</TableCell>
                                </TableRow>
                            ))}

                    </TableBody>
                    <TableFooter>
                        <TablePagination
                            component="div"
                            count={allMessages.length}
                            page={currentPage}
                            rowsPerPage={10}
                            rowsPerPageOptions={[10]}
                            onPageChange={(event, nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>;
}

export default UDPServerNOCPresentation;