import axios from 'axios';

const baseURL = process.env.REACT_APP_TCP_SERVER_API_BASE_URL;

const instance = axios.create({
    baseURL,
    timeout: 1000,
});

export const listAllTCPServerEvents = () => instance.get('/events');

export const listAllConnectedDevices = () => instance.get('/device');


export const getDevice = (deviceId) => instance.get(`/device/${deviceId}`);
export const disconnectDevice = (deviceId) => instance.delete(`/device/${deviceId}`);

export const getDeviceMessages = (deviceId) => instance.get(`/device/${deviceId}/messages`);

export const sendMessage = (deviceId, data) => instance.put(`/device/${deviceId}/send`, data);


